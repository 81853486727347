<template>
	<router-link :to="{ hash: 'content'}" class="button__explore">
		<div>
			<svg xmlns="http://www.w3.org/2000/svg" width="11.733" height="9.314" viewBox="0 0 11.733 9.314">
				<g id="Group_901" data-name="Group 901" transform="translate(0 0.353)">
					<path id="Path_392" data-name="Path 392" d="M0,0H10.647" transform="translate(0 4.305)" stroke="#FFF" stroke-width="1"/>
					<path id="Path_391" data-name="Path 391" d="M0,0V6.086H6.086" transform="translate(6.723 8.607) rotate(-135)" fill="none" stroke="#FFF" stroke-width="1"/>
				</g>
			</svg>
		</div>
		<span>Explore</span>
	</router-link>
</template>

<script>
// Export
export default {
	name: 'ButtonExplore',
}
</script>

<style lang="scss" scoped>

.button__explore {
	@include gutter('margin-top', 1);
	display: inline-flex;
	align-items: center;

	div {
		border-radius: 200px;
		border: 1px solid #fff;
		background: rgba(255,255,255,0);
		display: inline;
		height: 36px;
		width: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;
		transition: all $transition-1;
	}

	span {
		color: #fff;
	}

	svg {
		transform: rotate(90deg);
		transition: color $transition-1;
	}

	&:hover {

		span {
			//color: color_('primary', 'dark');
		}

		div {
			background-color: #fff;
			border-color: #fff;
		}

		svg {
			filter: invert(1);
		}
	}
}
</style>
