<template>
	<div class="hero">
		<div class="hero__container">
			<div class="hero__text">
				<slot></slot>

				<button-explore> </button-explore>
			</div>
		</div>
		<span id="content"></span>
	</div>
</template>

<script>
import ButtonExplore from '@/components/ButtonExplore'

// Export
export default {
	name: 'Hero',
	components: {
		ButtonExplore
	},
}
</script>

<style lang="scss" scoped>
$min-height-desktop: 600px;
$min-height-mobile: 480px;

$max-height-desktop: 800px;

.hero {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	//min-height: $min-height-mobile;
	height: 100vh;
	position: relative;

	@include media-query-min-width('tablet') {
		//min-height: $min-height-desktop;
		//@include ratio('hero');
	}

	// @include media-query-min-width('desktop') {
	// 	min-height: initial;
	// 	max-height: $max-height-desktop;
	// }


	&__container {
		@include layout();
		@include gutter('padding-top', 7);
		@include gutter('padding-bottom', 5);
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
    	height: 100%;
		width: 100%;

		@include media-query-min-width('desktop') {
			justify-content: flex-end;
		}
	}

	&__text {
		text-shadow: $textshadow-1;

		figure {
			@include fontSize(20px);
			@include gutter('margin-bottom', .75);
			font-family: $font-family-secondary;
			letter-spacing: 1px;
			color: color_('primary', 'text');
			text-transform: uppercase;
			margin: 0 0 0 5px;

			@include media-query-min-width('tablet') {
				@include fontSize(24px);
			}

		}

		h1 {
			@include fontSize(56px);
			@include gutter('margin-bottom', 1.5);
			color: color_('primary', 'text');
			font-family: $font-family-secondary;
			font-weight: bold;
			margin-top: 0;
			text-transform: uppercase;
			line-height: .9;

			@include media-query-min-width('tablet') {
				@include fontSize(96px);
			}

			span {
				color: color_('secondary');
			}
		}

		p {
			@include fontSize(18px);
			line-height: 1.4;
			color: color_('primary','text');

			@include media-query-min-width('tablet') {
				max-width: 80%;
			}

			@include media-query-min-width('desktop') {
				max-width: 50%;
			}
		}

		& > * {
			$delay: 500ms;
			animation: fadeUp 1000ms $ease-out 1 forwards;
			opacity: 0;

			// @include breakpoint('mobile') {
			// 	opacity: 1;
			// }

			&:nth-child(1) {
				animation-delay: ($delay * 1);
			}

			&:nth-child(2) {
				animation-delay: ($delay * 1.5);
			}

			&:nth-child(3) {
				animation-delay: ($delay * 2);
			}

			&:nth-child(4) {
				animation-delay: ($delay * 2.5);
			}

			&:nth-child(5) {
				animation-delay: ($delay * 3);
			}

			// &:nth-of-type(4) {
			// 	animation: none;
			// }
		}
	}

}

@keyframes fadeUp {
	0% {
		opacity: 0;
		transform: translateY(50%);
	}

	80% {
		opacity: 1;
	}

	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}
</style>
